import React, { forwardRef } from "react";
import styled from "@emotion/styled";
import {
	spacingRestricted,
	fluidPercentageRestricted,
	screen,
} from "../../styles/mixins";
import {
	h0,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	pStandard,
	pLarge,
	pSmall,
	richText,
	meta,
	metaSmall,
	trustpilotSmall,
	display,
} from "../../styles/typography";
import { themeColors } from "../../styles/colors";
import PropTypes from "prop-types";
import FluidImage from "../elements/FluidImage";
import FluidImagerX from "../elements/FluidImagerX";
import { css } from "@emotion/react";

const SectionEl = styled.section`
	${spacingRestricted("padding-top", "xxl")};
	${spacingRestricted("padding-bottom", "xxl")};
	position: relative;
	margin-bottom: -1px;

	// theming
	background-color: ${(props) => themeColors[props.colorTheme].bg};
	color: ${(props) => themeColors[props.colorTheme].fg};

	// siblings remove padding-top
	& + & {
		padding-top: 0;
	}
`;

const BehindBg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	// theming
	background-color: ${(props) => themeColors[props.colorTheme].bg};
`;

const Bg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	// radius
	${(props) => {
		if (props.corner && props.cornerPosition) {
			const border =
				props.cornerPosition == "bottom"
					? "border-bottom-right-radius"
					: "border-top-right-radius";

			return css`
				${fluidPercentageRestricted(border, 66, "designM")}
			`;
		}
	}};

	// theming
	background-color: ${(props) => themeColors[props.colorTheme].bg};

	@media ${screen("md")} {
		${(props) => {
			if (props.corner && props.cornerPosition) {
				const border =
					props.cornerPosition == "bottom"
						? "border-bottom-right-radius"
						: "border-top-right-radius";

				return css`
					${fluidPercentageRestricted(border, 315, "designD")}
				`;
			}
		}};
	}
`;

const BgImage = styled(FluidImage)`
	width: 100%;
	height: 100%;
`;

const BgImagerX = styled(FluidImagerX)`
	width: 100%;
	height: 100%;
`;

const DisplayEl = styled.h2`
	${display};
	${spacingRestricted("margin-bottom", "s")};

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const Heading0El = styled.h1`
	${h0};
	text-align: center;
	${spacingRestricted("margin-bottom", "s")};

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const Heading1El = styled.h1`
	${h1};
	${spacingRestricted("margin-bottom", "s")};

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const Heading2El = styled.h2`
	${h2};
	${spacingRestricted("margin-top", "m")};
	${spacingRestricted("margin-bottom", "s")};

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const Heading3El = styled.h3`
	${h3};
	${spacingRestricted("margin-top", "m")};
	${spacingRestricted("margin-bottom", "s")};

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const Heading4El = styled.h4`
	${h4};
	${spacingRestricted("margin-top", "m")};
	${spacingRestricted("margin-bottom", "xs")};

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const Heading5El = styled.h5`
	${h5};
	${spacingRestricted("margin-top", "m")};
	margin-bottom: 0.4em;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const Heading6El = styled.h6`
	${h6};
	${spacingRestricted("margin-top", "m")};
	margin-bottom: 0.4em;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const ParaEl = styled.p`
	${pStandard};
	${spacingRestricted("margin-bottom", "s")};

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const ParaLargeEl = styled.p`
	${pLarge};
	${spacingRestricted("margin-bottom", "s")};

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const ParaSmallEl = styled.p`
	${pSmall};
	${spacingRestricted("margin-bottom", "s")};

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const TrustpilotSmallEl = styled.p`
	${trustpilotSmall};
	${spacingRestricted("margin-bottom", "s")};

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const MetaEl = styled.p`
	${meta};
	${spacingRestricted("margin-bottom", "s")};

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const MetaSmallEl = styled.p`
	${metaSmall};
	${spacingRestricted("margin-bottom", "s")};

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const RichTextEl = styled.div`
	${richText};

	${(props) =>
		props.small &&
		css`
			p,
			ol,
			li {
				${pSmall}
			}
		`}

	${(props) =>
		props.metaSmall &&
		css`
			p,
			ol,
			li {
				${metaSmall}
			}
		`}
`;

const Section = forwardRef(({ children, className, colorTheme = "light"}, ref) => (
	<SectionEl className={className} colorTheme={colorTheme} ref={ref}>
		{children}
	</SectionEl>
));

export const Spacing = styled.div`
	width: 100%;
	${(props) => {
		const {size = "m"} = props
		return (
			spacingRestricted("height", size)
		)
	}}
`;

export const BgEffectX = ({
	className,
	bgImage,
	colorTheme = "light",
	nextTheme = "dark",
	corner,
	media,
	loading,
	cornerPosition = "bottom",
	gatsbyImage = false,
}) => {
	return (
		<div className={className}>
			{corner && <BehindBg colorTheme={nextTheme} />}
			<Bg
				colorTheme={colorTheme}
				corner={corner}
				cornerPosition={cornerPosition}>
				{bgImage?.url && (
					<BgImagerX
						image={bgImage}
						sizes="100vw"
						media={media}
						loading={loading}
						alt={bgImage?.title}
						gatsbyImage={gatsbyImage}
					/>
				)}
			</Bg>
		</div>
	);
};

export const Heading0 = ({ children, className, as = "h1" }) => (
	<Heading0El className={className} as={as}>
		{children}
	</Heading0El>
);

export const Display = ({ children, className, as = "h2" }) => (
	<DisplayEl className={className} as={as}>
		{children}
	</DisplayEl>
);

export const Heading1 = ({ children, className, as = "h1" }) => (
	<Heading1El className={className} as={as}>
		{children}
	</Heading1El>
);

export const Heading2 = ({ children, className, as = "h2" }) => (
	<Heading2El className={className} as={as}>
		{children}
	</Heading2El>
);

export const Heading3 = ({ children, className, as = "h3" }) => (
	<Heading3El className={className} as={as}>
		{children}
	</Heading3El>
);

export const Heading4 = ({ children, className, as = "h4" }) => (
	<Heading4El className={className} as={as}>
		{children}
	</Heading4El>
);

export const Heading5 = ({ children, className, as = "h5" }) => (
	<Heading5El className={className} as={as}>
		{children}
	</Heading5El>
);

export const Heading6 = ({ children, className, as = "h6" }) => (
	<Heading6El className={className} as={as}>
		{children}
	</Heading6El>
);

export const Para = ({ children, className, as = "p", dangerouslySetInnerHTML }) => (
	<ParaEl className={className} as={as} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
		{children}
	</ParaEl>
);

export const ParaLarge = ({ children, className, as = "p" }) => (
	<ParaLargeEl className={className} as={as}>
		{children}
	</ParaLargeEl>
);

export const ParaSmall = ({ children, className, as = "p" }) => (
	<ParaSmallEl className={className} as={as}>
		{children}
	</ParaSmallEl>
);

export const TrustpilotSmall = ({ children, className, as = "p" }) => (
	<TrustpilotSmallEl className={className} as={as}>
		{children}
	</TrustpilotSmallEl>
);

export const Meta = ({ children, className, as = "p" }) => (
	<MetaEl className={className} as={as}>
		{children}
	</MetaEl>
);

export const MetaSmall = ({ children, className, as = "p" }) => (
	<MetaSmallEl className={className} as={as}>
		{children}
	</MetaSmallEl>
);

export const RichText = ({ className, as = "div", text, small, metaSmall }) => (
	<RichTextEl
		className={className}
		as={as}
		dangerouslySetInnerHTML={{ __html: text }}
		small={small}
		metaSmall={metaSmall}
	/>
);

Section.propTypes = {
	colorTheme: PropTypes.string,
};

Section.defaultProps = {
	colorTheme: "light",
};

export default Section;
